import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Platform, ToastController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.page.html',
  styleUrls: ['./thankyou.page.scss'],
})
export class ThankyouPage implements OnInit {
  placedOrder: any = [];
  subscription: Subscription;

  constructor(
    private router: Router,
    private platform: Platform,
    private route: ActivatedRoute,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  dismissCart() {
    this.modalCtrl.dismiss();
  }
}
